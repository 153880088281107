import React from "react";
import Seo from "../../components/seo";
import useTitleContext from "../../context/useTitleContext";
import { StaticImage } from "gatsby-plugin-image";
import "./ausstellung.scss";
import Link from "../../components/Navigation/Link";

function ausstellung() {
  useTitleContext("");
  return (
    <>
      <Seo
        title={"Online Ausstellung 'Was Interessieren mich die Bienen'"}
        description={
          "Alles was Sie schon immer über Bienen wissen wollten. Der Künstler Florian Haas setzt in dem großformatigen Linolschnitt 'Was interessieren mich die Bienen' vom Bienensterben, über die Biene Maja, bis zum genmanipulierten Mais MON-810 das Nutztier Biene in Szene"
        }
      ></Seo>

      <div className="ausstellung">
        <div className="ausstellung__viewport">
          <StaticImage
            src="./Ausstellungsbilder/007Roundup.jpg"
            alt=""
            className="ausstellung__vpImg"
            objectFit="contain"
            objectPosition="0 0"
          />

          <div className="ausstellung__heading">
            <h2 className="ausstellung__heading--h1">Onlineausstellung 2022</h2>
            <h3 className="ausstellung__heading--h2">
              Was interessieren mich die Bienen
            </h3>
            <h4 className="ausstellung__heading--h3">
              Linolschnitt 720cm x 200cm
            </h4>
          </div>

          <div className="ausstellung__foerderer">
            <p className="ausstellung__foerderer--p">
              Gefördert von der Beauftragten der Bundesregierung für Kultur und
              Medien
            </p>
            <Link to={"https://www.bbk-bundesverband.de/"}>
              <StaticImage
                src="./Logos/LOGO_BBK_RGB_plain.jpg"
                alt="Logo BBK"
                className="ausstellung__foerderer--logoBBK"
              />
            </Link>
            <Link to="https://www.bundesregierung.de/breg-de/bundesregierung/bundeskanzleramt/staatsministerin-fuer-kultur-und-medien/neustart-kultur-startet-1775272">
              <StaticImage
                src="./Logos/BKM_Neustart_Kultur_Wortmarke_neg_RGB_RZ.png"
                alt="Logo BKM Neustart Kultur"
                className="ausstellung__foerderer--logoNSK"
              />
            </Link>
            <Link to="https://www.kulturstaatsministerin.de">
              <StaticImage
                src="./Logos/BKM_Web_de Kopie.png"
                alt="Logo der Beauftragten der Bundesregierung für Kultur und Medien"
                className="ausstellung__foerderer--logoBKM"
              />
            </Link>
          </div>
        </div>

        <div className="ausstellung__komplettansicht">
          <StaticImage
            src="./Bienentapete 25 %.png"
            alt="Komplettansicht der Arbeit was interessiern mich die Bienen"
            className="ausstellung__komplettansichtImg"
          />
          <p className="ausstellung__komplettansichtText">
            Weltweit wird an den Zielen einer nachhaltigeren Wirtschaft für eine
            lebenswerte Zukunft gearbeitet. Ein Symbol dafür ist die Biene, die
            als Sympathieträger für eine bessere ökologische Welt in aller Munde
            ist.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/001Bestäubung.jpg"
          alt="'Tangram' Figuren die mit einem langen stab Blüten bestäuben"
          className="ausstellung__img1"
        />
        <div className="ausstellung__text1">
          <h4 class="ausstellung__h4">
            Warum die Bienen in Sichuan verschwunden sind
          </h4>
          <p>
            In der chinesischen Provinz Sichuan ist die Honigbienen durch den
            hohen Austrag von Pestiziden verschwunden. Den Bauern blieb daher
            nichts anderes übrig, als jetzt ihre Obstbäume von Hand selbst zu
            bestäuben. An mehreren Stellen des Wandbildes tauchen "Tangram"
            Figuren auf. Sie verweisen auf die chinesischen Bauern, die mit
            einem Stab überdimensionale Pollen, die eigentlich nur unter dem
            Mikroskop sichtbar werden, bestäuben.
          </p>
        </div>

        <StaticImage
          src="./Ausstellungsbilder/002Biene Maja.jpg"
          alt="Darstellung von 'Biene Maja'"
          className="ausstellung__img2"
        />
        <div className="ausstellung__text2">
          <h4 class="ausstellung__h4">
            Warum die Biene Maja eigentlich eine Wespe ist
          </h4>
          <p>
            Am Vorabend des 1. Weltkriegs erschien "Die Biene Maja". Das Buch
            war ein Bestseller, der seinem Autor Waldemar Bonsels Reichtum und
            Ehre verschaffte. In den Schützengräben des 1. Weltkriegs avancierte
            die Tierfabel zum meistgelesenen Buch. Bei den Verfilmungen wurde
            die Biene Maja fatalerweise schwarz-gelb gestreift, was sie zu einer
            Wespe werden ließ.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/003Bienenbraterei.jpg"
          alt="Foodtruck in dem eine Biene hinter dem Tresen eine larve Brät. Das Schild über dem Wagen besagt 'Bienenbraterei'"
          className="ausstellung__img3"
        />
        <div className="ausstellung__text3">
          <h4 class="ausstellung__h4">Sollen wir sie essen?</h4>
          <p>
            Im Mai 2013 veröffentlichte die "Food and Agriculture Organization"
            (FAO) eine Studie, welche die Rolle von Insekten für die Ernährung
            der Weltbevölkerung hervorhob. Insbesondere wurde dabei auf die
            Bedeutung der Larven der Honigbienen hingewiesen. Insekten sind
            nicht nur in Südostasien ein fester Bestandteil des Speiseplans,
            denn auch in Nordhessen wurde nicht nur in Notzeiten Maikäfersuppe
            oder kandierter Maikäfer gerne gegessen. Insekteneiweiß stellt eine
            Alternative zu der CO2 intensiven Tierzucht von Kühen und Schweinen
            da.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/004Wilhelm Busch.jpg"
          alt="Eine Biene mit Flasche reicht diese an eine Krippe mit einem Bienenkind"
          className="ausstellung__img4"
        />
        <div className="ausstellung__text4">
          <h4 class="ausstellung__h4">Schnurrdiburr</h4>
          <p>
            Die Protagonisten der Wandarbeit, die Bienen, entstammen aus den
            unterschiedlichsten Quellen wie: „Found Footage“, Postkarten, Logos,
            historische Illustrationen oder naturkundlichen Grafiken. So geben
            sich dort auch Bienen aus der Bildergeschichte „Schnurrdiburr oder
            die Bienen“ (1872) von Wilhelm Busch ein Stelldichein.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/005Vegan.jpg"
          alt="Biene mit Sprechblase 'Honig = Bienen Erbrochenes und Bienen Geschissenes'"
          className="ausstellung__img5"
        />
        <div className="ausstellung__text5">
          <h4 class="ausstellung__h4">
            Bienen erbrochenes und Läuse geschissenes
          </h4>
          <p>
            Bienenhonig wird von Veganern verschmäht, da der Nektar im
            Honigmagen der Biene zum Bienenstock transportiert wird. Dort
            angekommen wird der Mageninhalt wieder herausgewürgt. Bis zum
            Zielort der Honigwabe passiert der Nektar oft noch weitere
            Honigmägen. Schlimmer noch, der sogenannte Honigtau wird durch Läuse
            erzeugt, welche die Tannennadeln anstechen, um den Pflanzensaft
            aufzusaugen. Von den Läusen ausgeschieden wir dann Zucker, den die
            Bienen zu Tannenhonig weiterverarbeiten.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/006Instrumelle Besamung.jpg"
          alt="Unter einem Mikroskop findet die Instrumentelle Besamung einer Bienenkönigin statt"
          className="ausstellung__img6"
        />
        <div className="ausstellung__text6">
          <h4 class="ausstellung__h4">Im Briefumschlag auf die Insel</h4>
          <p>
            Bienen werden global gehandelt und zum Beispiel als Paketbienen auf
            die Nordseeinseln verschickt. Männliche Drohnenvölker warten dort
            schon auf ihre Königinnen, um sie mit ihrem exklusiven Erbgut zu
            begatten. So werden Tausende von Königinnen in einem Briefumschlag
            per Post auf die lange Reise geschickt. Und da haben sie es noch gut
            erwischt, denn einige ihrer Kolleginnen werden in Labors
            instrumentell besamt, wofür sie auf eine Art "gynäkologischen Stuhl"
            geschnallt werden. Diese „Reinzuchtköniginnen“ sind dann besonders
            teuer.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/007Roundup.jpg"
          alt="Schild mit dem Text 'Maja Stirbt ROUNDUP' und eier sterbenden Biene Maja"
          className="ausstellung__img7"
        />
        <div className="ausstellung__text7">
          <h4 class="ausstellung__h4">Roundup</h4>
          <p>
            „Roundup“ von Monsanto (jetzt Bayer Konzern) ist ein
            Breitbandherbizid, welches den Wirkstoff Glyphosat enthält, der
            unter dem Verdacht steht, beim Menschen Krebs auszulösen. Aber auch
            Bienen können durch Glyphosat nachhaltig geschädigt werden.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/008Drohnen Kopie.jpg"
          alt="Rauchende Biene aus dem Buch Schnurrdiburr von Wilhelm Busch"
          className="ausstellung__img8"
        />
        <div className="ausstellung__text8">
          <h4 class="ausstellung__h4">Faule Drohnen</h4>
          <p>
            Als Drohnen bezeichnet man die männlichen Bienen. Wilhelm Busch
            schrieb etwas despektierlich über sie: Und nur die alten
            Brummeldrohnen Gefräßig, dick und faul und dumm, Die ganz umsonst im
            Hause wohnen, Faulenzen noch im Bett herum. „Hum!“ brummelt so ein
            alter Brummer, „Was, Dunner! Ist es schon so spät!?“
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/009Varroa Kopie.jpg"
          alt="Biene mit Varroamilbe auf dem Rücken"
          className="ausstellung__img9"
        />
        <div className="ausstellung__text9">
          <h4 class="ausstellung__h4">Varroa Destructor</h4>
          <p>
            Die Varroamilbe ist ein Parasit, der in fast jedem Bienenvolk
            heimisch ist. Dort ernähren sich die Milben vom Blut der Bienen
            (Hämolymphe). Der bevorzugte Lebensraum der Milbe ist die
            Drohnenbrut, von wo sie sich weiter ausbreitet, um dann
            Arbeiterinnen und Drohen zu befallen. Wird ein Bienenvolk nicht
            imkerlich und medizinisch betreut, kollabiert es unter dem
            Varroabefall innerhalb weniger Jahre.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/010Totenkopffalter.jpg"
          alt="Totenkopffalter"
          className="ausstellung__img10"
        />
        <div className="ausstellung__text10">
          <h4 class="ausstellung__h4">Tarnkappenbomber</h4>
          <p>
            Um sich zu ernähren, dringt der Totenkopfschwärmer in die
            Bienenstöcke ein, wo er sich von Honig ernährt. Um nicht gestochen
            zu werden, erzeugt der Falter eine Tarnkappe aus Pheromon, womit er
            dann wie das Bienenvolk riecht. Auf seiner Wanderschaft fliegt der
            Falter Tausende von Kilometer bis südlich der Sahara.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/011Langnese.jpg"
          alt="Sich erbrechende Biene über dem Schriftzug 'Langnese'"
          className="ausstellung__img11"
        />
        <div className="ausstellung__text11">
          <h4 class="ausstellung__h4">Flotte Biene</h4>
          <p>
            Der beliebte „Langnese“ Honig „Flotte Biene Wildblütenhonig“ setzt
            sich aus einem Cuvée verschiedener Honiglieferanten aus aller Welt
            zusammen. Daher wundert das Testergebnis der Stiftung Warentest mit
            mangelhaft nicht. Nach Stiftung Warentest: „Dürfte sich nicht
            Wildblütenhonig nennen, da die Pflanzenvielfalt fehlt und der
            Sortenanteil an Balsambaumgewächshonig zu hoch ist. Dieser prägt
            stark das Pollenspektrum.“ Balsambäume stammen aus Süd- und
            Mittelamerika, was einen deutlichen Hinweis auf die Herkunft des
            Honigs gib. Mexiko ist weltweit der größte Honigproduzent.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/012Bienensterben.jpg"
          alt="Skelett Bienen"
          className="ausstellung__img12"
        />
        <div className="ausstellung__text12">
          <h4 class="ausstellung__h4">Warum die Honigbiene nicht stirbt</h4>
          <p>
            Spricht man vom Bienensterben, so sind damit die Wildbienen gemeint!
            Die Honigbiene selbst fühlt sich pudelwohl, da hinter jeder Biene
            ein Imker steht, der sich um ihr Wohlergehen kümmert. Verbunden mit
            dem ökonomischen Nutzen der Bienen hat sich die Menge der
            Bienenvölker weltweit seit 1962 auf über 80 Millionen Völker
            verdoppelt.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/013Varroa Express.jpg"
          alt="Lok aus dem Kinderbuch 'Jim Knopf' und dem Schriftzug 'Varroa Express'"
          className="ausstellung__img13"
        />
        <div className="ausstellung__text13">
          <h4 class="ausstellung__h4">Der Varroaexpress</h4>
          <p>
            Der Bienenparasit Varroamilbe stammt ursprünglich aus Südostasien.
            Von dort hat er sich die Varroamilbe immer entlang der Trasse der
            Transsibirischen Eisenbahn von Bienenvolk zu Bienenvolk Richtung
            Westen ausgebreitet. 1967 tauchte die Milbe in Bulgarien auf, um
            dann 1978 in Frankfurt erstmals in Westeuropa gesichtet zu werden.
            Mittlerweile ist „Varroa Destructor“, außer in Australien fast
            weltweit verbreitet. Den einzelnen Stationen der Verbreitung folgend
            fährt der „Varroaexpress“ mit seinen Güterwägen immer im Kreis um
            das Wandbild.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/014Bahnhof.jpg"
          alt="Bahnhof Lummerland"
          className="ausstellung__img14"
        />
        <div className="ausstellung__text14">
          <h4 class="ausstellung__h4">Lummerland</h4>
          <p>
            Start des „Varroexpress“, dessen Lokomotive aus dem Kinderbuch „Jim
            Knopf und Lukas der Lokomotivführer“ entlehnt wurde, ist der Bahnhof
            auf Lummerland.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/015gruppe finger.jpg"
          alt="Logo der Künstlergruppe Finger"
          className="ausstellung__img15"
        />
        <div className="ausstellung__text15">
          <h4 class="ausstellung__h4">Gesellschaftsbienen</h4>
          <p>
            Die Künstlergruppe »finger« (Florian Haas und Andreas Wolf) zeichnet
            sich seit 2007 durch ihre ganz eigene Vorgehensweise aus, die die
            Lebenswelt der staatenbildenden Honigbienen in Analogie zu aktuellen
            gesellschaftlichen Entwicklungen und Themen setzt. Eine immer größer
            werdende Zahl von Bienenvölkern sind den beiden Künstlern dabei das
            »Material«, aus dem sie ihre Kunstbeiträge zu Umwelt- und
            Gesellschaftsprozessen realisieren.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/016Neues Museum für Bienen.jpg"
          alt="Logo des 'Neuen Museum für Bienen'"
          className="ausstellung__img16"
        />
        <div className="ausstellung__text16">
          <h4 class="ausstellung__h4">Neues Museum für Bienen</h4>
          <p>
            Das „Neue Museum für Bienen“ der „Künstlergruppe finger“ (Florian
            Haas und Andreas Wolf) ist explizit ein Museum für Bienen, das zu
            den Öffnungszeiten von den Bienen beflogen werden kann. Durch die
            Museumsräume aus Plexiglas, die auf den Bienenstöcken stehen, können
            auch die menschlichen Besucher die Bienen dabei beobachten, wie sie
            neugierig die Museumsexponate umfliegen. Jeder der Museumsräume ist
            einem für die Bienen relevanten Thema gewidmet.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/017MON810.jpg"
          alt="Mon 810 (Typ Bezeichnung für genmanipulierten Mais)"
          className="ausstellung__img17"
        />
        <div className="ausstellung__text17">""</div>
        <StaticImage
          src="./Ausstellungsbilder/018Bienenrennen.jpg"
          alt="Rennflagge"
          className="ausstellung__img18"
        />
        <div className="ausstellung__text18">
          <h4 class="ausstellung__h4">Erstes internationales Bienenrennen</h4>
          <p>
            2017 initiierte die „Künstlergruppe finger“ das erste internationale
            Bienenrennen. Wie Tauben, so fliegen auch Honigbienen wieder zu
            ihrem Geburtsort zurück. Start für die Rennbienen aus vier Ländern
            war das Dach des Jumeirah Hotels in Frankfurt, von wo das Ziel, das
            Museum für Moderne Kunst schon in Sichtweite war.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/019Kuh,Schwein,Biene.jpg"
          alt="eine Kuh, auf der ein Schwein steht, auf dem eine Biene Steht"
          className="ausstellung__img19"
        />
        <div className="ausstellung__text19">
          <h4 class="ausstellung__h4">Bestäubungsleistung</h4>
          <p>
            Gemessen an dem wirtschaftlichen Nutzen der Bestäubungsleistung ist
            die Honigbiene nach Rind und Schwein das dritt wichtigste Nutztier.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/020gemischte Bienengruppe.jpg"
          alt="Logo der gemischten Bienengruppe"
          className="ausstellung__img20"
        />
        <div className="ausstellung__text20">
          <h4 class="ausstellung__h4">Die gemischte Bienengruppe</h4>
          <p>
            Seit 2008 hat die „Künstlergruppe finger“ (Florian Haas und Andreas
            Wolf) als Teil der „sozialen Stadtimkerei“ unterschiedliche
            „gemischte Bienengruppen“ ins Leben gerufen. Hier können Menschen in
            schwierigen Lebenssituationen auf extrem langsame Art und Weise in
            einem geschützten Rahmen die Imkerei erlernen, um eine neue
            Lebensperspektive für sich zu gewinnen.
          </p>
        </div>
        <StaticImage
          src="./Ausstellungsbilder/022Ende=Atelieransicht.jpg"
          alt="Ansicht des gesammten Drucks im Raum"
          className="ausstellung__img21"
        />
      </div>
    </>
  );
}

export default ausstellung;
